<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import { ClientApiError } from '@shopware-pwa/types';
import { Bar, Button, Loader, LoginForm, Title } from '@upa/design-system';
import { useGoogleReCaptcha } from '~/composables/useGoogleRecaptcha';

const { recaptcha, isRecaptchaActive } = useGoogleReCaptcha();

const { push } = useRouter();

const { isLoggedIn, login } = useUser();
const { pushSuccess, pushError } = useNotifications();
const loginErrors = ref<string[]>([]);

const { t, locale } = useI18n();

const isLoading = ref(true);
onBeforeMount(() => {
  if (!isLoggedIn.value) {
    isLoading.value = false;
  } else {
    push(`/${locale.value}/account/`);
  }
});

//TODO: write composable
const goToAccount = () => {
  push(`/${locale.value}/account/`);
};

// Run login process on form submit
const invokeLogin = async (values: any): Promise<void> => {
  const formData: any = {
    username: values.email,
    password: values.password,
  };

  if (isRecaptchaActive()) {
    formData._grecaptcha_v3 = await recaptcha('login');
  }

  loginErrors.value = [];
  try {
    await login(formData);
    pushSuccess(t('components.loginForm.message.loginSuccess'));
    goToAccount();
  } catch (error) {
    //TODO: error handling if login is not succesfull. TBD with PMs
    const e = error as ClientApiError;
    loginErrors.value = e.messages.map(({ detail }) => detail);
    pushError(t('components.loginForm.message.badCredentials'));
  }
};

const goHome = () => push(`/${locale.value}/`);
</script>

<template>
  <Loader v-if="isLoading" style="z-index: 3; height: 100vh" />
  <Bar
    back-button
    :title="$t('components.account.mobileNavigation.breadcrumb.label')"
    @go-back="goHome"
  />
  <div class="login-container">
    <Title level="h3" :content="$t('components.loginForm.title')" />
    <LoginForm @submit-success-login="invokeLogin" />
    <div class="login-link__reset">
      <Button textable @click="push(`/${locale}/reset/`)">
        {{ $t('components.resetPasswordForm.title') }}
      </Button>
    </div>
    <Title
      level="h3"
      :content="$t('components.registrationModal.createNewAccount')"
      class="login__registration-title"
    />
    <div class="login-link__register">
      <Button textable @click="push(`/${locale}/registration/`)">
        {{ $t('components.registrationForm.submit') }}
      </Button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.login-container {
  max-width: 1240px;
  margin: 0 auto;
  padding: 40px 16px;

  .title {
    margin-bottom: 16px;
  }

  .login__registration-title {
    margin-top: 40px;
    text-align: center;
    @media (min-width: 1025px) {
      text-align: left;
    }
  }

  .login-link__reset,
  .login-link__register {
    margin-top: 40px;
    font-size: 14px;
    text-align: center;
    @media (min-width: 1025px) {
      text-align: left;
    }

    .button {
      font-family: var(--font-family--medium);
      font-size: 14px;
      color: var(--Primary-Blue, #5096e9);
      text-decoration: underline;
      text-transform: none;

      &:hover {
        color: #343434;
      }
    }
  }
}
</style>
